import React from 'react';
import { Box } from '@mui/material';
import { colors } from '../entrypoints/lib/theme';

const ContainerBox = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        bgcolor: colors.lightYellow,
        p: { xs: 2, sm: 4 },
        mt: 2,
        color: colors.navy,
        borderRadius: "0.5rem",
        ...props.sx
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default ContainerBox;
